<style scoped></style>

<template>
    <div>
        <div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
            <span>当前位置：</span>
            课程管理
            <span>&gt;</span>
            新增课程
            <button class="veadmin-iframe-button" onclick="history.back();">
                <i class="el-icon-back"></i>
            </button>
            <button class="veadmin-iframe-button" onclick="location.reload()">
                <i class="el-icon-refresh-right"></i>
            </button>
        </div>
        <div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
            <el-steps :active="0" finish-status="success" align-center style="margin-top: 20px">
                <el-step title="设置课程基本信息" description="进行中..."></el-step>
                <el-step title="设置课程标签"></el-step>
                <el-step title="设置发布平台"></el-step>
                <el-step title="设置推广"></el-step>
            </el-steps>
            <el-form ref="elForm" :model="course" status-icon :rules="rules" label-width="100px" style="margin: 20px">
                <el-form-item label="标题" prop="title">
                    <el-input v-model="course.title" placeholder="课程标题"></el-input>
                </el-form-item>
                <el-form-item label="简介" prop="profile">
                    <el-input type="textarea" v-model="course.profile" placeholder="课程简介"></el-input>
                </el-form-item>
                <el-form-item label="照片" prop="imgSRC">
                    <el-upload
                        class="upload-demo"
                        drag
                        :action="apiServer + '/api/1/admin/course/upload_image'"
                        :before-upload="beforeUpload"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :on-success="onSuccess"
                        :file-list="fileList"
                        :headers="header"
                        :multiple="false"
                        name="image"
                        list-type="picture"
                    >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                    </el-upload>
                    <el-dialog :visible.sync="dialog.visible">
                        <img width="100%" :src="dialog.imageUrl" alt="" />
                    </el-dialog>
                </el-form-item>
                <el-form-item label="是否推荐" prop="isNominate">
                    <el-switch v-model="course.isNominate" :change="showNominate()"></el-switch>
                    <div ref="setNominate" style="display: none">
                        <span style="margin-left: 10px">设置权重：</span>
                        <el-input
                            type="number"
                            v-model.number="course.nominate"
                            placeholder="从大到小排序"
                            @mousewheel.native.prevent
                            style="width: 150px"
                        ></el-input>
                        <span style="margin-left: 10px; font-size: small">权重>0，且从大到小排序</span>
                    </div>
                </el-form-item>
                <el-form-item label="原价" prop="costPrice">
                    <el-input v-model="course.costPrice" placeholder="课程原价" style="width: 200px"></el-input>
                </el-form-item>
                <el-form-item label="资源下载地址" prop="resourceUrl">
                    <el-input v-model="course.resourceUrl" placeholder="课程资源下载地址" style="width: 500px"></el-input>
                    <span style="margin-left: 20px">提取码：</span>
                    <el-input v-model="course.extractedCode" placeholder="课程资源提取码" style="width: 150px"></el-input>
                </el-form-item>
                <el-button type="primary" style="margin-left: 100px; width: 200px" @click="addCourse()">保存</el-button>
            </el-form>
        </div>
    </div>
</template>

<script>
import base from "@/base.js";
import { apiAddCourse } from "@/api/admin/course.js";

export default {
    data() {
        var checkEmpty = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("不能为空"));
            } else {
                callback();
            }
        };
        return {
            apiServer: base.apiServer,
            course: {
                title: "",
                profile: "",
                imgSrc: "",
                isNominate: false,
                nominate: "",
                costPrice: "",
                resourceUrl: "",
                extractedCode: ""
            },
            rules: {
                title: [{ validator: checkEmpty, trigger: "blur" }],
                profile: [{ validator: checkEmpty, trigger: "blur" }],
                resourceUrl: [{ validator: checkEmpty, trigger: "blur" }]
            },
            header: { token: this.$store.state.token },
            fileList: [],
            dialog: {
                visible: false,
                imageUrl: ""
            }
        };
    },
    methods: {
        beforeUpload(file) {
            if (file.size > 500 * 1024) {
                this.$message.error("图片大小不能超过500KB!");
                return false;
            } else {
                return true;
            }
        },
        handleRemove(file, fileList) {
            // console.log(file, fileList);
        },
        handlePreview(file) {
            // console.log("handlePreview",file);
            this.dialog.imageUrl = file.url;
            this.dialog.visible = true;
        },
        // 图片上传成功
        onSuccess: function (response) {
            if (response.code === 200) {
                this.$message({
                    message: "图片上传成功",
                    type: "success"
                });
                this.course.imgSrc = response.data;
            } else {
                console.log("response>>", response);
                this.$message.error(response.message);
            }
        },
        showNominate() {
            if (this.$refs.setNominate) {
                if (this.course.isNominate) {
                    this.$refs.setNominate.style.display = "unset";
                } else {
                    this.$refs.setNominate.style.display = "none";
                }
            }
        },
        addCourse() {
            //去除首尾空格
            this.course.title = this.course.title.trim();
            this.course.profile = this.course.profile.trim();

            var formData = new FormData();
            formData.append("title", this.course.title);
            formData.append("profile", this.course.profile);
            formData.append("imgSrc", this.course.imgSrc);
            formData.append("nominate", this.course.isNominate ? this.course.nominate : 0);
            formData.append("costPrice", this.course.costPrice);
            formData.append("resourceUrl", this.course.resourceUrl);
            formData.append("extractedCode", this.course.extractedCode);

            //调用接口 保存笔记
            apiAddCourse(this.$store.state.token, formData).then((response) => {
                if (response.data.code === 200) {
                    this.$message({
                        message: "保存成功！",
                        type: "success"
                    });
                    this.course.id = response.data.data;
                    //执行成功1秒后跳转页面
                    setTimeout(() => {
                        window.location.href = "/admin/course/setCourseTag?courseId=" + this.course.id + "&courseTitle=" + this.course.title;
                    }, 1000);
                } else {
                    console.log("response>>", response);
                    this.$message.error(response.data.message);
                }
            });
        }
    },
    mounted() {}
};
</script>
